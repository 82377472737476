import { useDynamicList, useRequest } from 'ahooks';
import { message, Col, Input, Row, Typography, Spin } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useEffect, useState } from 'react';
import {
  getCategoryAttributes,
  getRootRJMCategory,
  RJAttributeRequried,
} from '../../../../../services/rjmart/category.service';
import { RJNewProductAttribute } from '../../../../../services/rjmart/product-modal';

interface Attr extends RJNewProductAttribute {
  required: RJAttributeRequried;
}

export function AttributeInput(props: {
  value?: RJNewProductAttribute[];
  onChange?: (v: RJNewProductAttribute[]) => void;
  categoryId: number;
  projectId: string;
  isSale: 1 | 0;
}) {
  const key = 'AttributeInput';
  const [messageApi, contextHolder] = message.useMessage();
  // const [setRootCategory] = useState<RJMCategory>();
  // const { list, resetList, insert, replace, getKey } = useDynamicList<Attr>([]);
  const [list, setList] = useState<Attr[]>([]);
  const { run: loadCategoryAttributes, loading } = useRequest(
    getCategoryAttributes,
    {
      manual: true,
      debounceWait: 300,
      onBefore() {
        setList([]);
      },
      onSuccess(res) {
        if (res?.length) {
          const items: Attr[] = [];
          res.forEach((x, index) => {
            items.push({
              attributeName: x.attributeName,
              required: x.required,
              attributeValue:
                props.value?.find((y) => y.attributeName === x.attributeName)
                  ?.attributeValue || '',
            });
          });
          setList(items);
        }
      },
      onError(e, params) {
        messageApi.open({
          key,
          type: 'error',
          content: e.message,
          duration: 3,
        });
      },
      onFinally() {},
    }
  );
  const replace = (index: number, element: Attr) => {
    if (index < 0 && index >= list.length) {
      return list;
    }
    const newList = [...list];
    newList[index] = element;
    setList(newList);
    return newList;
  };
  useEffect(() => {
    if (props?.categoryId) {
      getRootRJMCategory(props.projectId || '', props?.categoryId).then((b) => {
        if (b) {
          // setRootCategory(b);
          loadCategoryAttributes({
            categoryId: b.platformId,
            projectId: props.projectId,
            isSale: props.isSale,
          });
        } else {
          messageApi.error('没有找到一级分类');
        }
      });
    }
  }, [props.categoryId]);
  return (
    <Spin spinning={loading}>
      {contextHolder}
      <Row gutter={24}>
        {list.map((element, index) => {
          return (
            <Col span={12} key={element.attributeName}>
              <FormItem
                label={element.attributeName}
                rules={[
                  {
                    required: element.required === RJAttributeRequried.Required,
                  },
                ]}
              >
                <Input
                  name={element.attributeName}
                  defaultValue={element.attributeValue}
                  onChange={(ev) => {
                    const newList = replace(index, {
                      ...element,
                      attributeValue: ev.target.value?.trim() || '',
                    });
                    props.onChange?.(newList);
                  }}
                />
              </FormItem>
            </Col>
          );
        })}
      </Row>
      {!list.length && (
        <Typography.Text type="secondary">
          当前品类无商品属性填写要求
        </Typography.Text>
      )}
    </Spin>
  );
}
