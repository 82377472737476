import { EditOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, message, Spin, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { CmtModalButton } from '../../../../components/CmtModalButton';
import { CasProductForm } from '../product-edit-form/product-form';
import { useParams } from 'react-router-dom';
import {
  MartProductPayload,
  MartProductSaleStatus,
  MartSimpleProductDto,
} from '../../../../services/cas/product-modal';
import { getProductDetail } from '../../../../services/cas/product.service';

function ChangeRJMProductForm(props: {
  data: MartSimpleProductDto;
  projectId: string;
  onSuccess?: (res: boolean) => void;
}) {
  const { data } = props;
  const [payload, setPayload] = useState<MartProductPayload>();

  const { loading, run } = useRequest(getProductDetail, {
    manual: true,
    debounceWait: 300,
    onSuccess: (res) => {
      setPayload(res);
    },
    onError(e) {
      message.error(e.message);
    },
  });

  useEffect(() => {
    if (data.insaleStatus !== MartProductSaleStatus.Publish) {
      // get detail
      run(data);
    }
  }, []);

  return (
    <Spin spinning={loading}>
      <div style={{ padding: '20px 0', width: '100%', minHeight: 200 }}>
        {payload && (
          <CasProductForm
            formValue={payload}
            projectId={props.projectId}
            onCancel={() => props.onSuccess?.(false)}
            onSuccess={() => props.onSuccess?.(true)}
            extendAction={() => (
              <Button onClick={() => props.onSuccess?.(false)}>关闭</Button>
            )}
          ></CasProductForm>
        )}
      </div>
    </Spin>
  );
}

export function EditButton(props: {
  data: MartSimpleProductDto;
  onSuccess?: () => void;
}) {
  const params = useParams();
  if (![MartProductSaleStatus.Unpublish].includes(props.data.insaleStatus)) {
    return <></>;
  }

  return (
    <CmtModalButton
      title="修改商品"
      width={'95%'}
      trigger={({ show }) => {
        return (
          <Tooltip title={'修改商品'}>
            <Button type="text" size="small" onClick={show}>
              <EditOutlined />
            </Button>
          </Tooltip>
        );
      }}
    >
      {({ hide }) => {
        return (
          <ChangeRJMProductForm
            data={props.data}
            projectId={params.projectId || ''}
            onSuccess={(res) => {
              hide();
              res && props.onSuccess?.();
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
