import { BarsOutlined } from '@ant-design/icons';
import { Spin, Space, Card, Steps, Typography, Button, Result } from 'antd';
import { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { RJMCategory } from '../../../../services/rjmart/category.service';
import { RJMCategoryCascader } from '../../../components/CategoryCascader';
import { ProductForm } from './product-form';
import { useTitle } from 'ahooks';

export function ProductFormPage() {
  const params = useParams();
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  useTitle('商品管理', { restoreOnUnmount: true });
  const stepItems = [
    // {
    //   title: '选择品类',
    //   content: (
    //     <Card>
    //       <Space direction="vertical" style={{ width: '100%' }}>
    //         <Typography.Title level={3}>选择品类和品牌</Typography.Title>
    //         <Typography.Paragraph>
    //           你需要至少选中到商品的二级分类，除医疗试剂耗材、仪器设备外的商品的三级分类非必选
    //         </Typography.Paragraph>
    //         <p>
    //           <RJMCategoryCascader
    //             onChange={(value) => {
    //               setSelectedCategory(value);
    //             }}
    //             queryParams={{ projectId: params.projectId || '' }}
    //           />
    //         </p>
    //         <Button
    //           onClick={() => {
    //             setCurrent(current + 1);
    //           }}
    //         >
    //           下一步
    //         </Button>
    //       </Space>
    //     </Card>
    //   ),
    // },
    {
      title: '商品信息',
      content: (
        <ProductForm
          formValue={{ categoryId: selectedCategory, skuInfos: [{}] }}
          projectId={params.projectId || ''}
          onSuccess={() => {
            setCurrent(current + 1);
          }}
        ></ProductForm>
      ),
    },
    {
      title: '提交',
      content: (
        <Card>
          <Result
            status="success"
            title={'商品添加成功'}
            subTitle="添加完成的商品处于「未上架」状态，可以到「我的商品」中进行管理"
            extra={[
              <Button type="primary" onClick={() => setCurrent(0)}>
                继续添加
              </Button>,
              <NavLink to={`../products`}>
                <Button>前往管理</Button>
              </NavLink>,
            ]}
          ></Result>
        </Card>
      ),
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: '锐竞', icon: <BarsOutlined /> },
        { name: '商品管理' },
        { name: '添加商品' },
      ]}
    >
      <Spin spinning={loading}>
        <Space style={{ width: '100%' }} direction="vertical">
          <Card>
            <Steps current={current} items={stepItems}></Steps>
          </Card>
          <div className="steps-content">{stepItems[current].content}</div>
        </Space>
      </Spin>
    </BlankWrapper>
  );
}
