import { BaseCategory, CategoryId } from '../../common/dtos/base-category';
import httpClient from '../http-client';

export interface RJMCategory extends BaseCategory {
  id: string;
  level: number;
  projectId: string;
  organizationId: string;
}

export enum RJAttributeRequried {
  Required = 1,
  Optional = 0,
}

export interface RJCategoryAttributeDTO {
  categoryId: number;
  attributeName: string;
  required: RJAttributeRequried;
}

export interface RJAttrbuite {
  categoryId: number;
  attributeName: string;
  required: RJAttributeRequried;
}

export interface RJRootCategoryAttrbuiteDto {
  categoryId: number;
  attributeDTOS: RJAttrbuite[];
}

let CacheCategories: Map<string, RJMCategory[]> = new Map();

export async function getAllRJMCategoriesApi(params: { projectId: string }) {
  if (!CacheCategories.get(params.projectId)?.length) {
    const cacheCategories: RJMCategory[] = await httpClient.get(
      '/rjmart/categories/all',
      {
        params,
      }
    );
    CacheCategories.set(params.projectId, cacheCategories);
  }

  return CacheCategories.get(params.projectId) || [];
}

export async function getRootRJMCategory(
  projectId: string,
  categoryId: number
): Promise<RJMCategory | undefined> {
  const categories = await getAllRJMCategoriesApi({ projectId });
  const getParent = (catId: CategoryId): RJMCategory | undefined => {
    const cat = categories.find((x) => x.platformId === catId);
    if (cat?.platformParentId) {
      return getParent(cat.platformParentId);
    }
    return cat;
  };
  return getParent(categoryId);
}

export async function getCategoryAttributes(params: {
  categoryId: CategoryId;
  isSale: 1| 0,
  projectId: string;
}): Promise<RJAttrbuite[] | null> {
  return await httpClient.get('/rjmart/categories/queryCategoryAttribute', {
    params,
  });
}
