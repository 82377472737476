export interface RJResponse<T extends unknown> {
  message: string;
  data?: T;
  success: boolean;
}

export enum RJProductStatus {
  /**
   * 草稿
   */
  Draft = 2,
  /**
   * 上架
   */
  Publish = 3,
  /**
   * 下架
   */
  Unpublish = 4,
  /**
   * 强制下架
   */
  ForceUnpublish = 5,
  /**
   * 待审核
   */
  ApprovalPending = 12,
  /**
   * 已驳回
   */
  Reject = 15,
}

export const RJProductStatuOptions = [
  { name: '草稿', value: RJProductStatus.Draft },
  { name: '已上架', value: RJProductStatus.Publish },
  { name: '已下架', value: RJProductStatus.Unpublish },
  { name: '强制下架', value: RJProductStatus.ForceUnpublish },
  { name: '待审核', value: RJProductStatus.ApprovalPending },
  { name: '已驳回', value: RJProductStatus.Reject },
];

export const RJDangerousTypes = [
  { name: '其他', value: 1 },
  { name: '其他危化品', value: 2 },
  { name: '易制毒', value: 3 },
  { name: '易制爆', value: 4 },
  { name: '剧毒品', value: 5 },
  { name: '精神/麻醉品', value: 6 },
  { name: '民用爆炸物品', value: 7 },
  { name: '医用毒性品', value: 8 },
  { name: '常规化学品', value: 9 },
  { name: '放射性同位素', value: 10 },
  { name: '菌毒株', value: 11 },
  { name: '病毒', value: 12 },
];

/**
 * 货期
 */
export const RJDeliveryTimes = [
  { name: '现货', value: 1 },
  { name: '1周内', value: 7 },
  { name: '2周', value: 14 },
  { name: '3周', value: 21 },
  { name: '1个月', value: 30 },
  { name: '2个月', value: 60 },
  { name: '2个月以上', value: 61 },
  { name: '咨询供应商客服', value: -1 },
];

export interface RJProductQueryParams {
  /**
   * 分页参数，第几页
   */
  page: number;
  /**
   *分页参数，每页多少个
   */
  size: number;
  /**
   * 商品货号(支持分词搜索)
   */
  productNum?: string;
  /**
   * 商品名称(支持分词搜索)
   */
  productName?: string;
  /**
   * 品牌id集合
   * 查询商品可选择的品牌接口: /openapi/product/getSuppBrands
   */
  brandIds?: number[];
  /**
   * 分类id集合
   * 查询树形结构的分类列表接口: /openapi/product/queryAllCategoryTree
   */
  categoryId?: number;

  /**
   * 状态(2:草稿，3:上架，4下架，5强制下架，12待审核，15已驳回)
   */
  status?: RJProductStatus;
}

export interface RJSimpleProductDto {
  id: number;
  brandName: string;
  /**
   * 品牌id(若为0是无品牌)
   */
  brandId: number;
  /**
   * 商品分类id（为子集的分类）
   */
  categoryId: number;
  photo: string;
  /**
   * 商品货号
   */
  code: string;
  name: string;
  /**
   * 商品库存
   */
  sku: number;
  unit: string;
  supplierName: string;
  status: RJProductStatus;
  /**
   * 规格描述
   */
  specification: string;
  price: number;
  /**
   * cas号
   */
  casNo: string;
  /**
   * 危化品类型
   */
  dangerousType: number;
  // 驳回原因
  rejectComment: string;
}

export interface RJCategoryModel {
  id: number;
  name: string;
}

export interface RJProductSpecificationDto {
  packingSpecificationValue: number;
  packingSpecificationUnit: string;
  minSpecificationValue: number;
  minSpecificationUnit: string;
}

export interface RJDangerousDto {
  type: number;
  casNoname: string;
}

export interface RJBrandDto {
  id: number;
  name: string;
  cname: string;
  ename: string;
  logo: string;
  manufacture: string;
}

export interface RJPhotoDto {
  photo: string;
}

export interface RJPriceDifferent {
  orgId: number;
  orgName: string;
  productId: number;
  price: number;
}

export interface RJNewProductAttribute {
  attributeName: string;
  attributeValue: string;
}

export interface RJProductDetailDto {
  id: string;
  code: string;
  name: string;
  suppId: number;
  suppName: string;
  brandId: number;
  brandName: string;
  categoryId: number;
  categoryName: string;
  categories: RJCategoryModel[];
  dangerousInfo: {
    casNo: string;
    dangerousType: number;
    regulatoryType: number;
  };
  photos: RJPhotoDto[];
  unit: string;
  deliveryTime: number;
  desc: string;
  video: string;
  attributes: RJNewProductAttribute[];
  skuInfos: {
    id: number;
    spuId: number;
    code: string;
    price: number;
    directoryPrice: number;
    saleAmount: number;
    stock: number;
    unit: string;
    sku: string;
    carryFeeTemplateId: number;
    specification: {
      packingSpecificationValue: number;
      packingSpecificationUnit: string;
      minSpecificationValue: number;
      minSpecificationUnit: string;
    };
    skuAttributes: RJNewProductAttribute[];
    differentPrices?: RJPriceDifferent[];
  }[];
}


/**
 * 新ProductPayload
 */
export interface RJNewProductPayload {
  /**
   * 商品id/spuid
   */
  id?: string;
  /**
   * 商品货号
   */
  code: string;
  /**
   * 商品名称/spu名称
   */
  name?: string;
  /**
   * 商品所属分类id, 必须是二级或者三级, 医疗试剂耗材类的必须是三级. (数据来源: 查询分类列表的接口/openapi/product/queryAllCategoryTree)
   */
  categoryId: number;
  brandId: number;
  /**
   * 危化品信息
   */
  dangerousInfo?: {
    /** cas号（危险化学品分类和化学试剂分类，必须传cas号） */
    casNo: string;
    /**危化品类型（危险化学品分类和化学试剂分类会根据分类自动匹配该字段；后勤物资分类和科研用中药材分类必须传该字段） */
    dangerousType: number;
  };
  photos: RJPhotoDto[];
  unit: string;
  deliveryTime: number;
  /**
   * 商品介绍 (支持富文本格式)
   */
  desc: string;
  video: string;
  status: RJProductStatus.Draft | RJProductStatus.Publish;
  /**基础属性*/
  attributes: RJNewProductAttribute[];
  /** 申诉原因 */
  reason?: string;
  /**
   * sku信息
   */
  skuInfos: {
    /**
     * 商品规格id/skuId
     */
    id?: number | null;
    /** 平台编码 */
    code: string;
    /**统一售价 */
    price: number;
    /**目录价 */
    directoryPrice?: number;
    /**库存 */
    stock: number;
    /**运费模板（不传默认为全国包邮） */
    carryFeeTemplateId: number;
    /**化学品规格（危险化学品分类和化学试剂分类必须传） */
    /**差异价 */
    differentPrices?: { orgId: string; price: number }[];
    specification?: unknown;
    /**规格属性/销售属性 */
    skuAttributes: RJNewProductAttribute[];
  }[];
}


export interface RJSupportBrand {
  brandId: number;
  cname: string;
  ename: string;
}

export interface RJUnitAndSpecificationDef {
  /**
   * 标准单位列表数据
   */
  unitList: string[];
  /**
   * 标准规格列表数据
   */
  specificationList: {
    name: string;
    minNeed: number;
  }[];
  /**
   * 标准最小规格列表数据
   */
  minSpecificationList: string[];
}
export interface RJDeliverCostTemplate {
  id: number;
  templateName: string;
}

export interface PictureListItem {
  photo: string;
}
